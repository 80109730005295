import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({
    headersBloque1: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      // { text: 'Optimizado'   , value: 'optimizado'  },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 1'      , value: 'lu1_n'       },
      { text: 'Martes 1'     , value: 'ma1_n'       },
      { text: 'Miércoles 1'  , value: 'mi1_n'       },
      { text: 'Jueves 1'     , value: 'ju1_n'       },
      { text: 'Viernes 1'    , value: 'vi1_n'       },
    ],

    headersBloque2: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      // { text: 'Optimizado'   , value: 'optimizado'  },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 2'      , value: 'lu2_n'       },
      { text: 'Martes 2'     , value: 'ma2_n'       },
      { text: 'Miércoles 2'  , value: 'mi2_n'       },
      { text: 'Jueves 2'     , value: 'ju2_n'       },
      { text: 'Viernes 2'    , value: 'vi2_n'       },
    ],

    headersBloque3: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      // { text: 'Optimizado'   , value: 'optimizado'  },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 3'      , value: 'lu3_n'       },
      { text: 'Martes 3'     , value: 'ma3_n'       },
      { text: 'Miércoles 3'  , value: 'mi3_n'       },
      { text: 'Jueves 3'     , value: 'ju3_n'       },
      { text: 'Viernes 3'    , value: 'vi3_n'       },
    ],

    headersBloque4: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      // { text: 'Optimizado'   , value: 'optimizado'  },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 4'      , value: 'lu4_n'       },
      { text: 'Martes 4'     , value: 'ma4_n'       },
      { text: 'Miércoles 4'  , value: 'mi4_n'       },
      { text: 'Jueves 4'     , value: 'ju4_n'       },
      { text: 'Viernes 4'    , value: 'vi4_n'       },
    ],

    headers:[],
    selectedHeaders: 1,

    checkEliminados: false,
    checkGruposApoyo: false,
    checkOptimizados: 3,
    curso: null,
    fechaActual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

  }),

  created(){

  },

  watch: {
  	selectedHeaders( val ){
  		switch( val ){
  			case 1:
  				this.headers = this.headersBloque1
  			break;

  			case 2:
  				this.headers = this.headersBloque2
  			break;

  			case 3:
  				this.headers = this.headersBloque3
  			break;

  			case 4:
  				this.headers = this.headersBloque4
  			break;
  		}
  	}
  },

  computed:{
  	filterGrupos(){

  		let grupos = []


  		if( this.checkEliminados ){
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 1 })
  		}else{
  			grupos = this.gruposRoles.filter( el => { return el.deleted == 0 })
  		}

      if( this.checkGruposApoyo ){
        grupos = grupos.filter( el => { return el.grupo.match('APOYO') })
      }

  		let idPlanteles = this.getdatosUsuario.planteles.map(( registro ) => { return registro.idplantel })

      if( this.getEscuela == 2 ){
        idPlanteles.push( 16 )
      }else{
        idPlanteles.push( 17 )
      }

      if( ![382, 161, 626, 7, 568].includes( this.getdatosUsuario.iderp ) ){
        grupos = grupos.filter( el => { return idPlanteles.includes( el.id_plantel )  })
      }

  		if( this.nivel ){
  			grupos = grupos.filter( el => { return el.id_nivel == this.nivel })
  		}

  		if( this.plantel ){
  			grupos = grupos.filter( el => { return el.plantel == this.plantel })
  		}

  		if( this.horario ){
  			grupos = grupos.filter( el => { return el.hora_inicio == this.horario })
  		}

  		if( [0,1].includes(this.modalidad)){
  			grupos = grupos.filter( el => { return el.online == this.modalidad })
  		}

      if( this.curso ){
        grupos = grupos.filter( el => { return el.curso == this.curso })
      }

  		if( this.teacherFilter ){
  			grupos = grupos.filter( el => { return el.lu1_n == this.teacherFilter || el.ma1_n == this.teacherFilter || el.mi1_n == this.teacherFilter || el.ju1_n == this.teacherFilter || el.vi1_n == this.teacherFilter || el.lu2_n == this.teacherFilter || el.ma2_n == this.teacherFilter || el.mi2_n == this.teacherFilter || el.ju2_n == this.teacherFilter || el.vi2_n == this.teacherFilter || el.lu3_n == this.teacherFilter || el.ma3_n == this.teacherFilter || el.mi3_n == this.teacherFilter || el.ju3_n == this.teacherFilter || el.vi3_n == this.teacherFilter || el.lu4_n == this.teacherFilter || el.ma4_n == this.teacherFilter || el.mi4_n == this.teacherFilter || el.ju4_n == this.teacherFilter || el.vi4_n  == this.teacherFilter })
  		}

  		return grupos
  	},

    gruposCompletos( ){
      return this.filterGrupos.filter( el => { return el.lu1_n && el.ju1_n  })
    },

    gruposFaltantes( ){
      return this.filterGrupos.filter( el => { return !el.lu1_n || !el.ju1_n  })
    },

    horasSemana( ){
      return this.filterGrupos.map(item => item.horas_ciclo ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCatorcena( ){
      return this.filterGrupos.map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCiclo( ){
      return this.filterGrupos.map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
    },

    filterSinBitacora( ){

      // Cantidad de grupos que ya tuvieron su clase
      let gruposActivos = this.filterGrupos

      // Ahora hay que ver la fecha de hoy
      let contador = 0

      for( const i in gruposActivos ){


        const { lunes, martes, miercoles, jueves, viernes, sabado, domingo, lu1_fecha, ma1_fecha, mi1_fecha, ju1_fecha, vi1_fecha, lu2_fecha, ma2_fecha, mi2_fecha, ju2_fecha, vi2_fecha, lu3_fecha, ma3_fecha, mi3_fecha, ju3_fecha, vi3_fecha, lu4_fecha, ma4_fecha, mi4_fecha, ju4_fecha, vi4_fecha } = gruposActivos[i]


        if( gruposActivos[i].iderp == 14564 ){
          console.log( lunes, lu1_fecha, gruposActivos[i].lu1, gruposActivos[i].lu1_bitacora  )
        }

        if( lu1_fecha <= this.fechaActual && !gruposActivos[i].lu1_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu1 ) ){
          contador +=1
        }

        if( lu2_fecha <= this.fechaActual && !gruposActivos[i].lu2_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu2 ) ){
          contador +=1
        }

        if( lu3_fecha <= this.fechaActual && !gruposActivos[i].lu3_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu3 ) ){
          contador +=1
        }

        if( lu4_fecha <= this.fechaActual && !gruposActivos[i].lu4_bitacora && ( lunes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].lu4 ) ){
          contador +=1
        }


        if( ma1_fecha <= this.fechaActual && !gruposActivos[i].ma1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma1 ) ){
          contador +=1
        }

        if( ma2_fecha <= this.fechaActual && !gruposActivos[i].ma2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma2 ) ){
          contador +=1
        }

        if( ma3_fecha <= this.fechaActual && !gruposActivos[i].ma3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma3 ) ){
          contador +=1
        }

        if( ma4_fecha <= this.fechaActual && !gruposActivos[i].ma4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ma4 ) ){
          contador +=1
        }


        if( mi1_fecha <= this.fechaActual && !gruposActivos[i].mi1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi1 ) ){
          contador +=1
        }

        if( mi2_fecha <= this.fechaActual && !gruposActivos[i].mi2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi2 ) ){
          contador +=1
        }

        if( mi3_fecha <= this.fechaActual && !gruposActivos[i].mi3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi3 ) ){
          contador +=1
        }

        if( mi4_fecha <= this.fechaActual && !gruposActivos[i].mi4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].mi4 ) ){
          contador +=1
        }

        if( ju1_fecha <= this.fechaActual && !gruposActivos[i].ju1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju1 ) ){
          contador +=1
        }

        if( ju2_fecha <= this.fechaActual && !gruposActivos[i].ju2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju2 ) ){
          contador +=1
        }

        if( ju3_fecha <= this.fechaActual && !gruposActivos[i].ju3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju3 ) ){
          contador +=1
        }

        if( ju4_fecha <= this.fechaActual && !gruposActivos[i].ju4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].ju4 ) ){
          contador +=1
        }

        if( vi1_fecha <= this.fechaActual && !gruposActivos[i].vi1_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi1 ) ){
          contador +=1
        }

        if( vi2_fecha <= this.fechaActual && !gruposActivos[i].vi2_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi2 ) ){
          contador +=1
        }

        if( vi3_fecha <= this.fechaActual && !gruposActivos[i].vi3_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi3 ) ){
          contador +=1
        }

        if( vi4_fecha <= this.fechaActual && !gruposActivos[i].vi4_bitacora && ( martes || sabado || domingo ) && ![0, 1202].includes( gruposActivos[i].vi4 ) ){
          contador +=1
        }


      }


      return contador

      // !item.grupo.match('ONLINE') && item.ma1_fecha <= fecha_hoy && !item.ma1_hora_asistencia ? 'yellow' : '' "
      // ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma1_bitacora "
    }

  },

	methods: {

    exportExcel(dataExport, name){
      let data = XLSX.utils.json_to_sheet(dataExport)
      const workbook = XLSX.utils.book_new()
      const filename = name
      XLSX.utils.book_append_sheet(workbook, data, filename)

      var wbout = XLSX.write(workbook, {
        bookType: 'csv',
        bookSST: false,
        type: 'binary'
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.csv')

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append('file',file);
      // /*getDatosRiesgo the form data*/

      this.$http.post('pdfs',formData).then(response=> {
        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.csv'
      }).catch(error=> {
        console.log(error);
      });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

  }
}
